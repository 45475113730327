<div class="panel-body" style="color:black;padding-bottom:30px;background-color:rgb(250, 250, 250)" *ngIf="cloudServer && queueInfo">
    <div fxLayout="row">
        <div fxFlex="1%"></div>
        <div fxFlex="50%">
            <h2 class="ng-binding">Cloud Server - Current Queue Length :
                {{queueInfo.queueLength}}
            </h2>
        </div>
        <div fxFlex="2%">
        </div>

        <div fxFlex="1%">
        </div>
    </div>
    <div fxLayout="row">
        <div fxFlex="10%">
        </div>
        <div fxFlex="30%">
            Loaded in ASR Cloud Queue : {{cloudServer.queue.length}}
        </div>
    </div>
    <div fxLayout="row">
        <div fxFlex="10%">
        </div>
        <div fxFlex="30%">
            Last Success : {{dayJS(cloudServer.lastSuccess).format('MM-DD HH:mm:ss')}}
        </div>
    </div>
    <div fxLayout="row">
        <div fxFlex="10%">
        </div>
        <div fxFlex="30%">
            Last Failure : {{dayJS(cloudServer.lastFailure).format('MM-DD HH:mm:ss')}}
        </div>
    </div>
    <div fxLayout="row">
        <div fxFlex="10%">
        </div>
        <div fxFlex="30%">
            Last ASR Error : {{dayJS(cloudServer.lastError).format('MM-DD HH:mm:ss')}}
        </div>
    </div>
</div>
<hr>
<mat-card>
    <mat-card-header style="display:unset">
        <div fxLayout="row" style="align-items:center">
            <h3 fxFlex="10%">
            </h3>

            <div fxFlex="20%">

            </div>
            <div fxFlex="auto">
            </div>
            <div fxFlex="20%">
            </div>
            <div fxFlex="3%">
            </div>
            <div fxFlex="1%"></div>
            <div fxFlex="3%">
            </div>
            <div fxFlex="1%"></div>
        </div>
    </mat-card-header>
    <mat-card-content>
        <ag-grid-angular style=" height: 500px;" class="ag-theme-alpine" (gridReady)="onGridReady2($event)" [gridOptions]="monitorGridOptions">
        </ag-grid-angular>
        <div id="myChart" class="ag-theme-alpine my-chart"></div>
    </mat-card-content>
</mat-card>
<hr>
<mat-card>
    <mat-card-header style="display:unset">
        <div fxLayout="row" style="align-items:center">
            <h3 fxFlex="10%">
            </h3>

            <div fxFlex="20%">

            </div>
            <div fxFlex="auto">
            </div>
            <div fxFlex="20%">
            </div>
            <div fxFlex="3%">
            </div>
            <div fxFlex="1%"></div>
            <div fxFlex="3%">
            </div>
            <div fxFlex="1%"></div>
        </div>
    </mat-card-header>
    <mat-card-content>
        <ag-grid-angular style=" height: 500px;" class="ag-theme-alpine" (gridReady)="onGridReady($event)" [gridOptions]="queueGridOptions">
        </ag-grid-angular>
    </mat-card-content>
</mat-card>