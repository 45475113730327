import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { HttpClient } from '@angular/common/http';
import { DashService } from '../../services/dash.service';
import { MatDialog } from '@angular/material/dialog';
import {
    Grid, ServerSideStoreType, AgChartThemeOverrides,
    ChartMenuOptions,
    ColDef,
    CreateRangeChartParams,
    FirstDataRenderedEvent,
    GridApi,
    GridReadyEvent,
    ValueFormatterParams
} from '@ag-grid-community/core';
import { GridOptions } from '@ag-grid-community/core';

import { FilenameBoxComponent } from '../../dialogs/filename-box/filename-box.component';
import { CopylinkBoxComponent } from '../../dialogs/copylink-box/copylink-box.component';
import { BuildQueryComponent } from '../../dialogs/query-builder/query-builder.component';
import { TransferService } from 'src/app/services/transfer.service';
import dayjs from 'dayjs';

export interface GetQueue {
    queueLength: number;
    queueLengthBS: QueueLengthB[];
    info: any[];
    autoStart: boolean;
}

export interface QueueLengthB {
    QueueLength: number;
    ASREngineVersion: string;
}


export interface JobQueueStatus {
    success: boolean;
    queue: Queue[];
    failures: Failure[];
    asrErrors: ASRError[];
    lastSuccess: string;
    lastError: string;
    lastFailure: string;
}

export interface ASRError {
    guid: string;
    request: string;
    error: string;
    errorDate: string;
}

export interface Failure {
    guid: string;
    license: License;
    src: string;
    size: number;
    audioDestination: string;
    uploadedFileName: string;
    redact: boolean;
    channelToTranscribe: number | null;
    modelDirectory: ModelDirectory;
    bitrate: number;
    frequency: number;
    brickDirectory: BrickDirectory;
    splitThreshold: number;
    modelName: ModelName;
    asrOptions: string;
    transcribeOptions: TranscribeOptions;
    startDate: string;
    log: string[];
    fileExtension: FileExtension;
    pcmFileNameOrig: string;
    rawFileName: string;
    duration: number;
    channelsToProcess: ChannelsToProcess[];
    transcribeSent?: string;
    transcriptStatus: FailureTranscriptStatus;
    failureDate: string;
    transcribeCount?: number;
    transcribeReceived?: string;
    reportResultCount?: number;
    languageSent?: string;
    durationXML?: number;
    reportSent?: string;
    jobComplete?: string;
}

export enum BrickDirectory {
    Allstar3 = "/allstar3",
    EMS = "/EMS",
    ListenTrust2 = "/ListenTrust2",
    ListenTrustEngMarketing = "/ListenTrustEngMarketing",
    ListenTrustSPCompliance = "/ListenTrustSpCompliance",
    MarketForce = "/MarketForce",
    Monitoring = "/monitoring",
    RobGrahamEnterprises = "/robGrahamEnterprises",
    Telebrands = "/telebrands",
}

export interface ChannelsToProcess {
    channel: number;
    filename: string;
    pcmFileName: string;
    transcribeResponse?: EResponse | null;
    transcribeStatus?: TranscribeStatus;
    languageResponse?: EResponse;
    languageStatus?: LanguageStatus;
}

export interface EResponse {
    success: boolean;
    request_id: string;
    error: null;
}

export interface LanguageStatus {
    status: Status;
    language: string;
    userdata: string;
}

export enum Status {
    Completed = "Completed",
    Failed = "Failed",
    Queued = "Queued",
    Received = "Received",
    Reported = "Reported",
}

export interface TranscribeStatus {
    status: Status;
    progress?: null | string;
    userdata: string;
    error?: null | string;
}

export enum FileExtension {
    Mp3 = "mp3",
    Wav = "wav",
}

export enum License {
    IgniteVTRL26OL3IDtOL3IB5PT9I5V1WM76Z = "Ignite-VTRL26-OL3IDt-OL3IB5-PT9I5v-1wM76Z",
}

export enum ModelDirectory {
    HomeCentosAppTekASRMonModelsENTelProc = "/home/centos/AppTekASRMon/models/EN.tel/proc",
}

export enum ModelName {
    ENTel = "EN.tel",
}

export interface TranscribeOptions {
    model: Model;
    redact: boolean;
    punc: boolean;
    diar: boolean;
    emotion: boolean;
    sentiment: boolean;
    languagemodel_id?: string;
}

export enum Model {
    EnUsTel = "en-us.tel",
    EsUsTel = "es-us.tel",
}

export enum FailureTranscriptStatus {
    Failed = "Failed",
    Logged = "Logged",
}

export interface Queue {
    guid: string;
    license: License;
    src: string;
    size: number;
    audioDestination: string;
    uploadedFileName: string;
    redact: boolean;
    channelToTranscribe: number | null;
    modelDirectory: ModelDirectory;
    bitrate: number;
    frequency: number;
    brickDirectory: BrickDirectory;
    splitThreshold: number;
    modelName: ModelName;
    asrOptions: string;
    transcribeOptions: TranscribeOptions;
    startDate: string;
    log: string[];
    fileExtension: FileExtension;
    pcmFileNameOrig: string;
    rawFileName: string;
    duration: number;
    channelsToProcess: ChannelsToProcess[];
    languageSent?: string;
    transcribeSent: string;
    transcriptStatus?: QueueTranscriptStatus;
    transcribeReceived?: string;
    reportResultCount?: number;
    transcribeCount?: number;
}

export enum QueueTranscriptStatus {
    Complete = "Complete",
}



@Component({
    selector: 'app-cloud-server',
    templateUrl: './cloud-server.component.html',
    styleUrls: ['./cloud-server.component.css']
})
export class CloudServerComponent implements OnInit {

    cloudServer!: JobQueueStatus;
    queueInfo!: GetQueue;
    dayJS = dayjs;
    queueGrid!: GridOptions;
    monitorGrid!: GridOptions;
    monitorGridOptions: GridOptions = {
        suppressAutoSize: false,
        enableCharts: true,
        onFirstDataRendered: this.onFirstDataRendered,
        columnDefs: [{
            headerName: 'Date',
            field: 'time',
            width: 155,
            suppressSizeToFit: true,
            cellRenderer: function (params) {
                if (params.data == null) {
                    return '';
                }
                if (params.data.time == null) {
                    return '<center>N/A</center>';
                }
                const date = new Date(params.data.time);
                const dateStr = date.toLocaleString();
                return '<center>' + dateStr + '</center>';
            }
        }, {
            headerName: 'Backlog',
            field: 'DBQueue',
            width: 320,
            suppressSizeToFit: true

        }, {
            headerName: 'Currently Processing',
            field: 'EngineQueue',
            width: 320,
            suppressSizeToFit: true

        }, {
            headerName: 'Speed Env 1',
            field: 'ASRServerSpeed',
            width: 320,
            suppressSizeToFit: true

        }, {
            headerName: 'Speed Env 2',
            field: 'ASRStageServerSpeed',
            width: 320,
            suppressSizeToFit: true

        }]
    };
    queueGridOptions: GridOptions = {
        suppressAutoSize: false,
        columnDefs: [{
            headerName: 'GUID',
            field: 'guid',
            width: 320,
            suppressSizeToFit: true

        }, {
            headerName: 'Start Date',
            field: 'startDate',
            width: 155,
            suppressSizeToFit: true,
            cellRenderer: function (params) {
                if (params.data == null) {
                    return '';
                }
                if (params.data.startDate == null) {
                    return '<center>N/A</center>';
                }
                const date = new Date(params.data.startDate);
                const dateStr = date.toLocaleString();
                return '<center>' + dateStr + '</center>';
            }

        }, {
            headerName: 'Sent to Transcribe',
            field: 'transcribeSent',
            width: 155,
            suppressSizeToFit: true,
            cellRenderer: function (params) {
                if (params.data == null) {
                    return '';
                }
                if (params.data.transcribeSent == null) {
                    return '<center>N/A</center>';
                }
                const date = new Date(params.data.transcribeSent);
                const dateStr = date.toLocaleString();
                return '<center>' + dateStr + '</center>';
            }

        }, {
            headerName: 'Processing Time',
            field: 'transcripstartDatetSent',
            width: 125,
            suppressSizeToFit: true,
            cellRenderer: function (params) {
                if (params.data == null) {
                    return '';
                }
                if (params.data.startDate == null) {
                    return '<center>N/A</center>';
                }
                let date = new Date(params.data.startDate);
                const date2 = new Date();
                let timeDiff = Math.abs(date2.getTime() - date.getTime());
                timeDiff = timeDiff / (1000 * 60);
                return '<center>' + timeDiff.toFixed(1) + '</center>';
            }

        }, {
            headerName: 'Duration',
            field: 'duration',
            width: 80,
            suppressSizeToFit: true
        }, {
            headerName: 'XML Duration',
            field: 'durationXML',
            width: 80,
            suppressSizeToFit: true
        }, {
            headerName: 'Status',
            field: 'transcriptStatus',
            width: 155,
            suppressSizeToFit: true,
            cellRenderer: function (params) {
                if (params.data == null) {
                    return '';
                }
                if (params.data.transcriptStatus == null) {
                    return '<center>Waiting for Transcript</center>';
                }
                if (params.data.transcriptStatus) {
                    return params.data.transcriptStatus;
                }
            }

        }],

        rowSelection: 'single',
        headerHeight: 50,
        onGridReady: function (params) {
            params.api.sizeColumnsToFit();
        }
    };


    failuresGrid = {
        suppressAutoSize: false,
        columnDefs: [{
            headerName: 'GUID',
            field: 'guid',
            width: 320,
            suppressSizeToFit: true,
            suppressFilter: true

        }, {
            headerName: 'Start Date',
            field: 'startDate',
            width: 155,
            suppressSizeToFit: true,
            cellRenderer: function (params) {
                if (params.data == null) {
                    return '';
                }
                if (params.data.startDate == null) {
                    return '<center>N/A</center>';
                }
                const date = new Date(params.data.startDate);
                const dateStr = date.toLocaleString();
                return '<center>' + dateStr + '</center>';
            }

        }, {
            headerName: 'Sent to Transcribe',
            field: 'transcribeSent',
            width: 155,
            suppressSizeToFit: true,
            cellRenderer: function (params) {
                if (params.data == null) {
                    return '';
                }
                if (params.data.transcribeSent == null) {
                    return '<center>N/A</center>';
                }
                const date = new Date(params.data.transcribeSent);
                const dateStr = date.toLocaleString();
                return '<center>' + dateStr + '</center>';
            }

        }, {
            headerName: 'Failure Date',
            field: 'failureDate',
            width: 175,
            suppressSizeToFit: true,
            cellRenderer: function (params) {
                if (params.data == null) {
                    return '';
                }
                if (params.data.failureDate == null) {
                    return '<center>N/A</center>';
                }
                const date = new Date(params.data.failureDate);
                const dateStr = date.toLocaleString();
                return '<center>' + dateStr + '</center>';
            }
        }, {
            headerName: 'Duration',
            field: 'duration',
            width: 80,
            suppressSizeToFit: true
        }, {
            headerName: 'XML Duration',
            field: 'durationXML',
            width: 80,
            suppressSizeToFit: true
        }, {
            headerName: 'Status',
            field: 'transcriptStatus',
            width: 155,
            suppressSizeToFit: true,
            cellRenderer: function (params) {
                if (params.data == null) {
                    return '';
                }
                if (params.data.transcriptStatus == null) {
                    return '<center>Waiting for Transcript</center>';
                }
                if (params.data.transcriptStatus) {
                    return params.data.transcriptStatus;
                }
            }

        }],
        enableSorting: true,
        enableFilter: true,
        rowSelection: 'single',
        headerHeight: 50,
        onGridReady: function (params) {
            params.api.sizeColumnsToFit();
        }
    };


    errorsGrid = {
        columnDefs: [{
            headerName: 'GUID',
            field: 'guid',
            width: 320,
            suppressSizeToFit: true,
            suppressFilter: true

        }, {
            headerName: 'Request ID',
            field: 'request',
            width: 255,
            suppressSizeToFit: false

        }, {
            headerName: 'Error',
            field: 'error',
            width: 355,
            suppressSizeToFit: false,
            cellRenderer: function (params) {
                if (params.data == null) {
                    return '';
                }
                return params.data.error;
            }

        }, {
            headerName: 'Error Date',
            field: 'errorDate',
            width: 175,
            suppressSizeToFit: true,
            cellRenderer: function (params) {
                if (params.data == null) {
                    return '';
                }
                if (params.data.errorDate == null) {
                    return '<center>N/A</center>';
                }
                const date = new Date(params.data.errorDate);
                const dateStr = date.toLocaleString();
                return '<center>' + dateStr + '</center>';
            }
        }]
    };


    currentChartRef: any;

    constructor(
        private authService: AuthService,
        private httpClient: HttpClient,
        public dashService: DashService,
        private matDialog: MatDialog,
        private transferService: TransferService
    ) { }

    onGridReady(grid: GridOptions): void {
        this.queueGrid = grid;
    }

    onGridReady2(grid: GridOptions): void {
        this.monitorGrid = grid;
    }

    onFirstDataRendered(params: FirstDataRenderedEvent) {
        if (this.currentChartRef) {
            this.currentChartRef.destroyChart();
        }
        let createRangeChartParams = {
            chartContainer: document.querySelector('#myChart') as any,
            suppressChartRanges: true,
            cellRange: {
                columns: ['time', 'DBQueue', 'EngineQueue', 'ASRServerSpeed', 'ASRStageServerSpeed'],
            },
            chartType: 'line',
        };
        this.currentChartRef = params.api.createRangeChart(createRangeChartParams as CreateRangeChartParams);
    }

    ngOnInit(): void {
        this.authService.reConnect();
        this.getAllData();
        setInterval(this.getAllData, 60000);
    }

    getAllData(): void {
        this.httpClient.get<GetQueue>('api/dashboardV2/getMonitorLog', {
            params: {}
        }).subscribe((res: GetQueue) => {
            if (this.monitorGrid) this.monitorGrid?.api?.setRowData(res['data'] as []);
        });
        this.httpClient.get<GetQueue>('/api/aws_control/getQueue', {
            params: {}
        }).subscribe((res: GetQueue) => {
            if (res) this.queueInfo = res;
        });
        this.httpClient.get<JobQueueStatus>('https://stage.lucidcx-tek.com/cloudServer/jobQueueStatus', {
            params: {}
        }).subscribe((res: JobQueueStatus) => {
            if (res.success) {
                this.cloudServer = res;
                if (this.queueGrid) this.queueGrid?.api?.setRowData(this.cloudServer.queue);
            }
        });
    };

}
