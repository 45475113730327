import { Component, OnInit, OnDestroy, Inject, ChangeDetectorRef, NgZone, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '../../services/auth.service';
import { KeywordList, SelectedTranscript } from '../../services/dash.service';
import { TranscriptService, Word, Keyword } from '../../services/transcript.service';
import * as WaveSurfer from 'wavesurfer.js';
import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline';
import RegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions';
import { faFrown, faSmile, faMeh } from '@fortawesome/free-regular-svg-icons';
import { TransferService } from '../../services/transfer.service';
import { MatDialog } from '@angular/material/dialog';
import { CopylinkBoxComponent } from '../../dialogs/copylink-box/copylink-box.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { Clipboard } from '@angular/cdk/clipboard';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
    selector: 'app-transcript-window',
    templateUrl: './transcript-window.component.html',
    styleUrls: ['./transcript-window.component.css']
})
export class TranscriptWindowComponent implements OnInit, OnDestroy {
    @ViewChild('copyLink') public copyLinkRef;
    faFrown = faFrown;
    faSmile = faSmile;
    faMeh = faMeh;
    transcriptContainer!: HTMLElement;
    tranScript!: HTMLElement;
    currentWord = 0;
    viewStatusTimer;
    rowStatus = '';


    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { selectedTranscript: SelectedTranscript; dashService: any; node: any },
        public authService: AuthService,
        public transcriptService: TranscriptService,
        private changeDetectorRef: ChangeDetectorRef,
        private ngZone: NgZone,
        private transferService: TransferService,
        private matDialog: MatDialog,
        private spinner: NgxSpinnerService,
        private clipboard: Clipboard,
        private httpClient: HttpClient,
        private snackbar: MatSnackBar
    ) { }

    ngOnInit(): void {
        this.transcriptService.loadTranscript(this.data.selectedTranscript);
        if (!this.data.node) return;
        this.rowStatus = this.data.node.data.status;
        if (this.data.dashService.currentKeywordValues?.length)
            this.data.dashService.selectedKeyword = this.data.dashService.currentKeywordValues[0].keyword;
        else this.data.dashService.selectedKeyword = null;
        this.viewStatusTimer = setTimeout(() => {
            this.viewStatusTimer = null;
            if (this.data.node.data.status === 'Not Viewed') {
                this.data.node?.setDataValue('status', 'Viewed');
                this.rowStatus = 'Viewed';
            }
            this.data.dashService.grid.api?.refreshCells();
        }, 10000);
    }
    statusChange(): void {
        this.data.node?.setDataValue('status', this.rowStatus);
        this.data.dashService.grid.api?.refreshCells();
    }

    copySelectedText(): void {
        let selectionText = '';
        window.getSelection()?.toString().split('\n').forEach(word => {
            selectionText += word + ' ';
        });
        selectionText = selectionText.trim();
        let firstNode;
        let lastNode;
        const selection = window?.getSelection()?.getRangeAt(0).cloneContents().childNodes;
        if (selection && selection?.[0]['className'].indexOf('line2') > 1) {
            if (selection[0]['children'][1])
                firstNode = selection[0]['children'][1]?.['className'];
            else
                firstNode = selection[0]['children'][0]?.['className'];
        } else {
            firstNode = selection ? selection[0]['className'] : null;
        }
        const firstWord = this.transcriptService.words[firstNode.match(/ G([0-9]+) /)[1]];

        if (selection && selection?.[selection.length - 1]['className'].indexOf('line2') > 1) {
            if (selection[selection.length - 1]['lastElementChild']?.['className'].indexOf('G-') > 1)
                lastNode = firstNode;
            else
                lastNode = selection ? selection[selection.length - 1]['lastElementChild']?.['className'] : null;
        } else {
            lastNode = selection ? selection[selection.length - 1]['className'] : null;
        }


        const lastWord = this.transcriptService.words[lastNode.match(/ G([0-9]+) /)[1]];
        if (!selectionText || !selection) return;
        const header = `Selected Word # ${firstWord.index} at ${firstWord.startTime} to Word # ${lastWord.index} at ${lastWord.endTime}
         \n ------------------------------------------------------------\n`;
        selectionText = selectionText.replace(/Speaker/g, '\nSpeaker');
        this.clipboard.copy(header + selectionText);
    }

    ngAfterViewInit(): void {
        this.transcriptService.currentTime = 0;
        this.spinner.show();
        if (this.transcriptService.transcript.mediaTypeGroup !== 'Reviews') {
            requestAnimationFrame(() => {
                this.transcriptService.wavesurfer = WaveSurfer.create({
                    container: '#audio-player',
                    waveColor: '#29fa83',
                    progressColor: '#198ee3',
                    cursorColor: '#d9fb36',
                    normalize: true,
                    skipLength: 15,
                    height: 70,
                    //            renderer: 'MultiCanvas',
                    hideScrollbar: true,
                    backend: 'MediaElement'
                });
                this.transcriptService.wavesurfer.addPlugin(RegionsPlugin.create()).initPlugin('regions');
                this.transcriptService.wavesurfer.on('audioprocess', (a: number) => {
                    this.transcriptService.currentTime = a as number;
                    this.newScroll(this.transcriptService.currentTime);
                });
                this.transcriptService.wavesurfer.on('seek', (e) => {
                    this.newScroll(this.transcriptService.wavesurfer.getDuration() * e);
                });
                this.transcriptService.wavesurfer.on('ready', () => {
                    this.spinner.hide();
                    this.changeDetectorRef.detectChanges();
                    //  const g =document.querySelector('#transcript-container')?.parentElement?.parentElement?.getAttribute('id');                  
                    this.transcriptContainer =
                        document.getElementById(document.querySelector('#transcript-container')?.parentElement?.parentElement?.getAttribute('id') || '') as HTMLElement;
                    this.tranScript = document.getElementById('transcript-container') as HTMLElement;
                    const interval = Math.trunc(this.transcriptService.wavesurfer.getDuration() / 25) || 120;
                    this.transcriptService.wavesurfer.addPlugin(TimelinePlugin.create({
                        container: '#wave-timeline',
                        fontSize: 12,
                        height: 40,
                        timeInterval: interval,
                        primaryLabelInterval: 4,
                        secondaryLabelInterval: 2
                    })).initPlugin('timeline');
                    this.transcriptService.pauses.forEach((pause) => {
                        this.transcriptService.wavesurfer.addRegion({
                            start: pause.pauseStart,
                            end: pause.pauseFinish,
                            color: '#d8f9ff',
                            drag: false,
                            resize: false,
                            id: 'pausetime'
                        });
                    });
                });

            });
        } else {
            this.spinner.hide();
        }
    }
    getDuration(): number {
        return isNaN(this.transcriptService.wavesurfer?.getDuration()) ? '0' : this.transcriptService.wavesurfer.getDuration();
    }
    playAudio(): void {
        if (this.transcriptService.wavesurfer.isPlaying()) {
            this.transcriptService.wavesurfer.pause();
            this.authService.userTrack({
                action: 'audio_stop',
                value: '',
                audioid: this.data.selectedTranscript.audioid
            });
        }
        else {
            this.transcriptService.wavesurfer.play();
            this.authService.userTrack({
                action: 'audio_start',
                value: '',
                audioid: this.data.selectedTranscript.audioid
            });
        }
    }
    skipForward(): void {
        this.transcriptService.wavesurfer.skipForward();
    }
    skipBack(): void {
        this.transcriptService.wavesurfer.skipBackward();
    }
    volumeChange(event: EventTarget | null): void {
        this.transcriptService.wavesurfer.setVolume((event as HTMLInputElement)?.value);
    }
    speedChange(event: EventTarget | null): void {
        this.transcriptService.wavesurfer.setPlaybackRate((event as HTMLInputElement)?.value);
    }

    inNewScroll = false;
    newScroll(currentTime: number): void {
        if (this.inNewScroll) return;
        this.changeDetectorRef.detectChanges();
        this.inNewScroll = true;
        this.currentWord = 0;
        this.transcriptService.words.find((word) => {
            if (this.currentWord && currentTime < word.startTime) return true;
            if (currentTime >= word.startTime) {
                this.currentWord = word.index;
            }
            return false;
        });
        const top = this.tranScript.offsetTop;
        const topHeight = this.tranScript.offsetHeight;
        const scrollOffset = (topHeight / 2) + top;
        const hover = this.tranScript.querySelector('.G' + this.currentWord) as HTMLElement;
        if (!hover || !hover.offsetTop) {
            this.inNewScroll = false;
            return;
        }
        this.transcriptContainer?.scrollTo(0, hover.offsetTop - scrollOffset);
        this.inNewScroll = false;
    }
    selectWord(word: Word): void {
        this.transcriptService.currentTime = word.startTime;
        this.transcriptService.wavesurfer.seekTo(word.startTime / this.transcriptService.wavesurfer.getDuration());
        this.authService.userTrack({
            action: 'select_transcript_word',
            value: word.word,
            audioid: this.data.selectedTranscript.audioid
        });
    }

    displayDate(stringDate: string): string {
        const date = new Date(stringDate);
        return date.toLocaleString();
    }

    saveTinyLink(): void {
        const transferState = {
            currentCategoryValues: this.data.dashService.currentCategoryValues,
            currentKeywordValue: { values: this.data.dashService.currentKeywordValues },
            fileName: this.data.dashService.fileName,
            selectedPhrase: null,
            searchComment: this.data.dashService.commentField,
            advancedQeury: null,
            reportEndDate: this.data.dashService.selectedDate.endDate.toDate(),
            reportStartDate: this.data.dashService.selectedDate.startDate.toDate(),
            justCommentValue: this.data.dashService.comment,
            selectedCategory: this.data.dashService.selectedCategory,
            sortDir: this.data.dashService.sortDir,
            sortColumn: this.data.dashService.sortColumn,
            agent: navigator.userAgent,
            sFilter: this.data.dashService.sFilter,
            selectedTranscript: this.data.selectedTranscript
        };
        const transferGuid = this.transferService.saveTinyLink(JSON.stringify(transferState));
        this.matDialog.open(CopylinkBoxComponent, {
            width: '600px',
            backdropClass: 'cdk-overlay-transparent-backdrop',
            hasBackdrop: true,
            data: { transferGuid, position: this.copyLinkRef }
        });
    }

    complianceClick(compliance): void {
        this.transcriptService.wavesurfer.clearRegions();
        this.transcriptService.wavesurfer.seekTo(compliance.complianceTime / this.transcriptService.wavesurfer.getDuration());
        setTimeout(() => { this.newScroll(compliance.complianceTime); }, 20);
        this.transcriptService.wavesurfer.addRegion({
            start: compliance.complianceTime - .85,
            end: (compliance.complianceEndTime || compliance.complianceEndTime) + .85,
            color: '#FF2F00',
            drag: false,
            resize: false,
            width: 14
        });
    }

    ngOnDestroy(): void {
        if (this.needToSaveComment) this.saveComment();
        if (this.viewStatusTimer) clearTimeout(this.viewStatusTimer);
        if (this.transcriptService.wavesurfer) this.transcriptService.wavesurfer.destroy();
        this.transcriptService.wavesurfer = null;
        this.transcriptService.words = [];
        this.transcriptService.compliances = [];
        this.transcriptService.keywords = [];
        this.transcriptService.history = [];
        this.transcriptService.comments = [];
        this.transcriptService.waveForm = '';
        this.transcriptService.audioEvents = '';
        this.transcriptService.lines = [];
    }

    wordSearchResult: Word[] = [];
    currentSearchWord = '';
    currentSearchWordCount = 0;

    wordSearch(event): void {
        const searchWord = event.target.value.toLowerCase();
        if (searchWord === '' || searchWord !== this.currentSearchWord) {
            this.wordSearchResult = [];
            this.currentSearchWord = '';
            this.currentSearchWordCount = 0;
            if (searchWord === '') return;
        }
        if (this.currentSearchWord === searchWord) {
            this.currentSearchWordCount++;
            if (this.currentSearchWordCount > this.wordSearchResult.length - 1) this.currentSearchWordCount = 0;
            this.selectWord(this.wordSearchResult[this.currentSearchWordCount]);
            return;
        }
        this.transcriptService.words.forEach((word) => {
            if (word.word.toLowerCase() === searchWord) {
                this.wordSearchResult.push(word);
            }
        });
        if (this.wordSearchResult.length) {
            this.currentSearchWord = searchWord;
            this.selectWord(this.wordSearchResult[0]);
        }
    }

    selectKeyword(keyword: string): void {
        this.data.dashService.selectedKeyword = keyword;
        const keywordToFind = this.data.dashService.selectedKeyword.split(' ');
        let jumpToFirst = false;
        this.transcriptService.words.forEach((word, index, words) => {
            let found = 0;
            keywordToFind.forEach((keyword, keywordIndex) => {
                if (keyword.toLowerCase() === words[index + keywordIndex].word.toLowerCase()) found++;
            });
            if (found === keywordToFind?.length) {
                this.transcriptService.wavesurfer.clearRegions();
                this.transcriptService.wavesurfer.addRegion({
                    start: word.startTime - .85,
                    end: word.endTime + .85,
                    color: '#FF2F00',
                    drag: false,
                    resize: false,
                    width: 14
                });
                keywordToFind.forEach((keyword, keywordIndex) => {
                    words[index + keywordIndex].isKeyword = true;
                });
                if (!jumpToFirst) {
                    this.selectWord(word);
                    jumpToFirst = true;
                }
            }

        });
        this.authService.userTrack({
            action: 'select_transcript_keyword',
            value: keyword,
            audioid: this.data.selectedTranscript.audioid
        });

    }

    isComplianceDash(): boolean { return this.authService.permission.ShowCompliance === 'true'; }

    downloadFile(): void {

        this.httpClient.post<string>('/api/dashboard/getSignedUrl', {
            params: {
                accountid: this.authService.userSessionData.accountid,
                key: this.data.selectedTranscript.audiolink.split('/')[5],
                filename: this.data.selectedTranscript.filename.split('/').pop()
            }
        }, {
            responseType: 'text' as 'json'
        }).subscribe((response: string) => {
            if (response.length === 0) {
                console.info('Some thing went wrong please try again');
                return;
            } else {
                const s3Link = response;
                const link = document.createElement('a');
                link.setAttribute('type', 'hidden');
                link.href = s3Link;

                document.body.appendChild(link);
                link.click();
                link.remove();
            }
        });
    }

    saveComment(): void {
        this.inputCommentText = this.inputCommentText.replace(/["']/g, '\'');
        const audioid = this.data.selectedTranscript.audioid;
        if (this.inputCommentText.length > 2) {
            this.httpClient.get('/api/dashboard/saveComment', {
                params: {
                    comment: this.inputCommentText,
                    userid: this.authService.userSessionData.userid,
                    audioid: audioid,
                    sessionkey: this.sessionKey
                }
            }).subscribe(() => {
                this.needToSaveComment = false;
            }, (e) => {
                console.log('error saving comment : ' + e);
            });
        }
        else {
            this.needToSaveComment = false;
        }
    }
    commentTimer;
    inputCommentText = '';
    needToSaveComment = false;
    sessionKey = (Math.trunc(Math.random() * 9000 * Math.random() * 19000)).toString();

    inputComment(e): void {
        clearTimeout(this.commentTimer);
        this.commentTimer = setTimeout(() => { this.saveComment(); }, 15000);
        this.needToSaveComment = true;
    }

    deleteComment(index: string): void {
        this.httpClient.delete('/api/dashboard/deleteComment', {
            params: {
                commentId: index,
                audioId: this.data.selectedTranscript.audioid
            }
        }).subscribe(() => {
            this.transcriptService.comments = this.transcriptService.comments.filter((comment) => index !== comment.id);
            this.changeDetectorRef.detectChanges();
        }, (e) => {
            console.log('error saving comment : ' + e);
        });
    }
    isCompliantChange(compliance: Keyword): void {
        this.httpClient.post<string>('/api/dashboard/isCompliantChange', {
            params: {
                audioComplianceId: compliance.audioComplianceId,
                isCompliant: compliance.isCompliant,
                userid: this.authService.userSessionData.userid
            }
        }, {

        }).subscribe((response: string) => {
            if (response['success']) {
                this.snackbar.open('Success updating Compliance', 'Dismiss', {
                    verticalPosition: 'top',
                    horizontalPosition: 'right',
                    duration: 2000,
                    panelClass: 'success'
                });
            } else {
                this.snackbar.open('Error updating Compliance', 'Dismiss', {
                    verticalPosition: 'top',
                    horizontalPosition: 'right',
                    duration: 2000,
                    panelClass: 'error'
                });
            }
        });
    }

}
