<div fxLayout="column" class="main-transcript">

    <div fxLayout="row" style="background-color:black;position: fixed;
    width: 80%;
    max-width: 80vw;
    z-index: 1000;">
        <div fxLayout="column" fxFlex="100%">
            <div fxLayout="row" style="height:43px">
                <div fxFlex="2%"></div>
                <div fxFlex="90%" style="margin-top:8px;color:white">CALL ANALYSIS</div>
                <div fxFlex="5%">
                    <button style="margin-top: -3px;" mat-button class="close-icon" [mat-dialog-close]="true">
                        <mat-icon class="close-window">cancel</mat-icon>
                    </button>
                </div>
            </div>
            <div fxLayout="row" style="background-color:#0090d9">
                <div fxFlex="5%"></div>
                <div fxFlex="87%" style="color:white">{{data.selectedTranscript.filename}}
                    <mat-icon style="cursor:grab;margin-left: 10px;" (click)="downloadFile()">file_download</mat-icon>
                </div>
                <div fxFlex="5%" style="cursor:grab;color:white">{{this.transcriptService.currentTime.toFixed(1)}}</div>

            </div>
        </div>
    </div>

    <div fxLayout="row" class="modal-header2">
        <div fxFlex=90%>
            <p class="call-title ng-binding">
                Audio record
                <span id="file_link" class="ng-scope">- <a style="color:black" href="#" ng-click="findKey($event)"
                        key="D36E7EAD-BA6D-45EA-815A-9601001BA7DD.wav">{{transcriptService.transcript.filename}}</a></span>
                <span id="audio_error" style="display:none;color: red; font-weight: bold;">-
                    Error Loading Audio</span>
            </p>
        </div>
        <div *ngIf="getDuration()" fxFlex=10%>
            <span id="audioSec" class="call-title" style="float:right">Duration : {{getDuration()}}</span>
        </div>
    </div>

    <div *ngIf="transcriptService.transcript.mediaTypeGroup!=='Reviews'" style="margin-left:25px;margin-right:25px" id="audio-player">

    </div>
    <div fxLayout="row" id="sentiment-container" width="100%" height="40px" style="margin-left:25px;margin-right:25px">
        <canvas id="sentiment-timeline" width="0"></canvas>
    </div>
    <div fxLayout="row" id="wave-timeline" style="margin-left:25px"></div>
    <div *ngIf="transcriptService.wavesurfer" fxLayout="row" class="controls ng-scope" style="margin-bottom: 5px;    padding: 25px;">
        <button fxFlex="auto" (click)="skipBack()" class="btn btn-primary btn-sm btn-control" data-action="back" style="display:inline-block">
            <mat-icon style="vertical-align: sub;">skip_previous</mat-icon>
            <span style="vertical-align: super;">Backward</span>
        </button>
        <button fxFlex="15%" (click)="playAudio()" class="btn btn-primary btn-sm btn-control" data-action="play" style="display:inline-block">
            <mat-icon *ngIf="!transcriptService.wavesurfer.isPlaying()" style="vertical-align: sub;">play_arrow</mat-icon>
            <span *ngIf="!transcriptService.wavesurfer.isPlaying()" style="vertical-align: super;">Play</span>
            <mat-icon *ngIf="transcriptService.wavesurfer.isPlaying()" style="vertical-align: sub;">pause</mat-icon>
            <span *ngIf="transcriptService.wavesurfer.isPlaying()" style="vertical-align: super;">Pause</span>
        </button>
        <button fxFlex="auto" (click)="skipForward()" class="btn btn-primary btn-sm btn-control" data-action="forth" style="display:inline-block">
            <mat-icon style="vertical-align: sub;">skip_next</mat-icon>
            <span style="vertical-align: super;">Forward</span>
        </button>
        <button fxFlex="auto" (click)="transcriptService.wavesurfer.toggleMute()" class="btn btn-primary btn-sm btn-control" data-action="toggle-mute"
            style="display:inline-block">
            <mat-icon *ngIf="!transcriptService.wavesurfer.getMute()" style="vertical-align: sub;">volume_off</mat-icon>
            <span *ngIf="!transcriptService.wavesurfer.getMute()" style="vertical-align: super;">Mute</span>
            <mat-icon *ngIf="transcriptService.wavesurfer.getMute()" style="vertical-align: sub;">volume_mute</mat-icon>
            <span *ngIf="transcriptService.wavesurfer.getMute()" style="vertical-align: super;">Unmute</span>
        </button>
        <div fxFlex="15%" style="display:inline-block">
            <mat-icon style="color: #626262;">stop_circle</mat-icon>
            <input (change)="speedChange($event.target)" style="display:inline-block;width:80px;padding: 4px;" value="1" type="range" min="0" max="2" step="0.1"
                class="">
            <mat-icon style="color: #626262;">speed</mat-icon>
        </div>
        <div fxFlex="25%" style="display:inline-block">
            <mat-icon style="color: #626262;">volume_down</mat-icon>
            <input (change)="volumeChange($event.target)" style="display:inline-block;width:80px;padding: 4px;" type="range" min="0" max="1" step="0.1"
                class="">
            <mat-icon style="color: #626262;">volume_up</mat-icon>

            <div class="row" style="display: inline-block!important;margin-left: 20px;">
                <div style="display: inline-block!important;margin-left: 20px;" *ngIf="data.selectedTranscript.sentiment" class="star-rating">
                    <div style="margin-top:2px" class="star-rating2 " style="display: inline-block!important;margin-left: 20px;">
                        <fa-icon *ngIf="data.selectedTranscript.sentiment===1" class="rating" [icon]="faFrown" style=" font-size: 40px;"></fa-icon>
                        <fa-icon *ngIf="data.selectedTranscript.sentiment===2" class="rating" [icon]="faFrown" style=" font-size: 34px;"></fa-icon>
                        <fa-icon *ngIf="data.selectedTranscript.sentiment===3" class="rating" [icon]="faMeh" style=" font-size: 30px;"></fa-icon>
                        <fa-icon *ngIf="data.selectedTranscript.sentiment===4" class="rating" [icon]="faSmile" style=" font-size: 34px;"></fa-icon>
                        <fa-icon *ngIf="data.selectedTranscript.sentiment===5" class="rating" [icon]="faSmile" style=" font-size: 40px;"></fa-icon>
                    </div>
                </div>
            </div>
        </div>
        <div fxFlex="23%">
        </div>
        <div #copyLink fxFlex="3%">
            <button matTooltip="Copy Query to URL" color="primary" matTooltipPosition="above" mat-mini (click)="saveTinyLink()">
                <mat-icon>content_copy</mat-icon>
            </button>
        </div>

    </div>

    <div fxLayout="row" fxFlex="60%">
        <mat-tab-group fxLayout="column" fxFlex="70%" animationDuration="0ms">
            <mat-tab label="Transcript">
                <div id="transcript-container" class="transcript-body" style="">
                    <div fxLayout="row" *ngFor="let line of transcriptService.lines" class="transcript-line2 ">
                        <div *ngFor="let word of line" [class]="'transcript-class G'+ word.index"
                            [ngClass]="{hovertext:currentWord===word.index,speaker:word.seq===-1,speaker0:word.speaker===0,speaker1:word.speaker===1,keyword:word.isKeyword}"
                            (click)="selectWord(word)">
                            {{word.word}}
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="History">
                <div class="history">
                    <mat-card>
                        <mat-card-content>
                            <div fxLayout="row" *ngFor="let line of transcriptService.history" class="history-line">
                                {{line.action}} on {{displayDate(line.auditdt)}} by {{line.username}}
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </mat-tab>
            <mat-tab label="Comments">
                <mat-card>
                    <mat-card-content>
                        <div fxLayout="row" *ngFor="let line of transcriptService.comments" class="comment-line">
                            <p>
                                <span style="font-size: smaller;color:grey">Comment from {{line.username}} on {{displayDate(line.dtAdded)}}</span>
                                <button style="margin-left:10px" matTooltip="Delete Comment" color="warn" matTooltipPosition="above" mat-icon-button
                                    (click)="deleteComment(line.id)">
                                    <mat-icon>delete_forever</mat-icon>
                                </button>
                            </p>
                            <p>
                                <span style="color:black"> {{line.comment}}</span>
                            </p>
                        </div>
                        <hr>
                        <textarea placeholder="Enter new comment here" id="comment_text" name="textarea"
                            style="margin-top:10px;color:black;width: 100%;height: 100px;border-color: Transparent; border: none;resize: none;"
                            (input)="inputComment($event)" [(ngModel)]="inputCommentText"></textarea>
                    </mat-card-content>
                </mat-card>
            </mat-tab>
        </mat-tab-group>
        <div fxLayout="column" fxFlex="2%" style=""></div>
        <div fxLayout="column" fxFlex="27%" style="    position: relative;
        overflow-x: hidden;
        overflow-y: auto;
        z-index: 1;
        flex-grow: 1;">
            <div fxLayout="row" fxFlex="10%" style="margin-bottom: 10px;">
                <button matTooltip="Copy Selected Text" color="primary" matTooltipPosition="above" mat-mini (click)="copySelectedText()">
                    Copy Selected Text
                </button>
            </div>
            <div fxLayout="row" fxFlex="5%"></div>
            <div fxLayout="row" fxFlex="10%" style="margin-bottom: 10px;">
                <mat-form-field appearance="fill" style="margin-bottom: 10px;">
                    <mat-select [(value)]="rowStatus" (selectionChange)="statusChange()">
                        <mat-option value="Viewed">
                            Viewed
                        </mat-option>
                        <mat-option value="Not Viewed">
                            Not Viewed
                        </mat-option>
                        <mat-option value="Flagged">
                            Flagged
                        </mat-option>
                        <mat-option value="Followed Up">
                            Followed Up
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxFlex="5%"></div>
            <div fxLayout="row" fxFlex="20%" style="margin-bottom: 10px;">
                <mat-form-field appearance="fill">
                    <mat-label>Free Word Search: {{ wordSearchResult.length?currentSearchWordCount+1:0}} of {{wordSearchResult.length}}</mat-label>
                    <input matInput name="wordSearch" (keydown.enter)="wordSearch($event)">
                </mat-form-field>

            </div>
            <div fxLayout="row" fxFlex="5%"></div>
            <div fxLayout="row" fxFlex="auto">
                <div fxLayout="column" *ngIf="isComplianceDash()" fxFlex="90%">
                    <div *ngFor="let compliance of transcriptService.compliances">
                        <button fxFlex="auto" class="compliance-button" style="margin-top: -3px;" (click)="complianceClick(compliance)">
                            {{compliance.complianceName.replaceAll('(Yes)','').replaceAll('(No)','')}}
                        </button>
                        <mat-form-field fxFlex="80px" appearance="fill">
                            <mat-select [(value)]="compliance.isCompliant" (selectionChange)="isCompliantChange(compliance)">
                                <mat-option [value]="1">
                                    Yes
                                </mat-option>
                                <mat-option [value]="0">
                                    No
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div fxLayout="row" fxFlex="auto" *ngIf="transcriptService?.keywords && transcriptService.keywords.length>0">
                <div fxLayout="column" fxFlex="60%">
                    Keywords
                    <div *ngFor="let keyword of transcriptService.keywords" style="margin-top:10px">
                        <button class="keyword-button" style="flex:none" (click)="selectKeyword(keyword.keyword)">
                            {{keyword.keyword}} ({{keyword.keywordCount}})
                        </button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>