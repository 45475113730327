import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { GridChartsModule } from '@ag-grid-enterprise/charts'
// eslint-disable-next-line max-len
LicenseManager.setLicenseKey('CompanyName=LS&S,LicensedApplication=LibraryIQ,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-013112,ExpiryDate=23_February_2022_[v2]_MTY0NTU3NDQwMDAwMA==7218db1b7bc5a0baf4bb2e50f474867c');



ModuleRegistry.registerModules([
    ServerSideRowModelModule,
    CsvExportModule,
    RangeSelectionModule,
    GridChartsModule
]);

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
